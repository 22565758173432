import React from "react"
import MasterLayout from "../components/MasterLayout"
import SingleParrallaxPage from "../components/SingleParrallaxPage"

export default function Home() {

  return (
    <MasterLayout>
      <SingleParrallaxPage>
      </SingleParrallaxPage>
    </MasterLayout>
  )
}
